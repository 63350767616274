import { useState, useEffect } from 'react'
import { switchMap } from 'rxjs'
import { MangoQuery, RxDocument } from 'rxdb'
import { empty, of } from 'rxjs'
import { useDbUtils } from './useDbUtils'
import { MetaData } from '@obeta/models/lib/models/Meta/Meta'
import { useRxDB } from 'rxdb-hooks'

export type EntityChange<T> = (prevEntities: T[], nextEntities: T[]) => void

export function useEntities<T>(
  entityName: string,
  mangoQuery?: MangoQuery,
  entityChange?: EntityChange<T>
): T[] {
  const [entities, setEntities] = useState<T[]>([])
  const { getCollection$ } = useDbUtils()

  useEffect(() => {
    const collBS = getCollection$(entityName) //collection behaviour subject
    const collPipe = collBS?.pipe(
      switchMap((coll) => {
        if (coll) {
          let queryToUse: MangoQuery | undefined = undefined
          if (mangoQuery) {
            queryToUse = mangoQuery
          }

          const qry = coll.find(queryToUse)
          return qry.$
        }
        return empty()
      })
    )

    const collSub = collPipe
      ?.pipe(
        switchMap((docs) => {
          const data = docs.map((doc: RxDocument<T>) => doc.toMutableJSON())

          return of(data)
        })
      )
      .subscribe((data: T[]) => {
        setEntities((oldData) => {
          entityChange && entityChange(oldData, data)
          return data
        })
      })

    return () => {
      collSub?.unsubscribe()
    }
  }, [entityName, mangoQuery, getCollection$, entityChange])

  return entities
}

/**
 * TODO: we store a lot of data inside metaData.
 * This means that modules that use meta data will be updated unnessesary.
 * Just because data that they do not neeed was updated. Only because this data is part of
 * meta data. We need better solution. Subscribe only to fields that you need from meta data
 *
 * TODO: I cannot use useEntities for metaData.
 * Reason: getCollection$ returns undefined for entitymeta collection.
 * I cannot make it part of entitiesToManage array. I will break other parts of the app
 */
export const useMetadata = (metaCol: string) => {
  const db = useRxDB()
  const [meta, setMeta] = useState<MetaData | null>(null)

  useEffect(() => {
    const sub = db.entitymeta
      .findOne({
        selector: {
          id: metaCol,
        },
      })
      .$.subscribe((doc: RxDocument<MetaData>) => {
        const meta = doc.toMutableJSON()
        setMeta(() => meta)
      })

    return () => {
      sub.unsubscribe()
    }
  }, [db, metaCol])

  return meta
}

export const useLocal = <DocType>(localName: string) => {
  const db = useRxDB()
  const [doc, setDoc] = useState<DocType | null>(null)

  useEffect(() => {
    const sub = db.getLocal$<DocType>(localName).subscribe((doc) => {
      const data = doc?.toMutableJSON().data
      setDoc(data || null)
    })

    return () => {
      sub.unsubscribe()
    }
  }, [db, localName])

  return doc
}
