import {
  catchError,
  concatMap,
  defer,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  retry,
  switchMap,
  withLatestFrom,
} from 'rxjs'
import Axios from 'axios-observable'
import {
  CustomerActionTypes,
  LoadProfileAction,
  loadUserProfileResult,
  SaveCustomerSettingsAction,
  saveCustomerSettingsResult,
  SaveNewAddressAction,
  ChangeImageAction,
  changeImageResult,
  DeleteImageAction,
  deleteImageResult,
  UpdateCardAction,
  updateCardResult,
  LoadUserGraphQLAction,
  loadUserGraphQLResult,
  UpdateUserSettingsAction,
  DeleteUserToken,
} from '../actions/customer-actions'

import {
  Customer,
  CustomerGeneralInformation,
  CustomerAccount,
  CustomerConfig,
} from '@obeta/models/lib/models/CustomerData/Customer'
import { deleteFirebasePushToken } from '@obeta/models/lib/models/Tokens/Tokens'
import { getUserV2Result, UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { OpenPost } from '@obeta/models/lib/models/OpenPost/OpenPost'
import { SyncDataWithDatabase } from '@obeta/models/lib/models/Db/index'

import { handleError } from '@obeta/utils/lib/datadog.errors'
import { ofType } from 'redux-observable'
import { AxiosResponse, AxiosError } from 'axios'
import { noop } from '../actions'
import { RxDatabase, CollectionsOfDatabase } from 'rxdb'
import { EventType, NotificationType, getEventSubscription } from '@obeta/utils/lib/pubSub'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import type { CustomerMetaData } from '../hooks/useUserData'
import { gqlQueryUserV2, gqlUpdateUserV2 } from '../queries/userV2'

interface ProfileResponseRefined {
  data: CustomerGeneralInformation
}

interface ImageElement {
  deleteType: string
  deleteUrl: string
  name: string
  size: number
  thumbnailUrl: string
  type: string
  url: string
}

interface ProfileImageUploadResponse {
  file: ImageElement[]
}

interface UserDetailsResponse {
  data: {
    user: CustomerConfig
    account: CustomerAccount
    openItems: OpenPost[]
  }
  messages: { type: string; message: string }[]
}

export interface CustomerSettings {
  creditLimitWarning: boolean
  orderConfirmation: boolean
  defaultProjectId: string
}

const dataURItoBlob = (dataURI: string, mime: string) => {
  const bstr = atob(dataURI)
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

const getMainUserPermissions = () => [
  'changeAdditionalText',
  'changeAddresses',
  'changeCommission',
  'changeDeliveryAddress',
  'showAccountInfo',
  'showPendingItems',
  'orderShoppingCart',
  'showCatalogPrices',
  'showListenPrices',
  'showPurchasePrices',
  'showOffers',
  'showOrders',
]

const createLoadUserProfile = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<LoadProfileAction>) =>
    actions$.pipe(
      ofType(CustomerActionTypes.LoadProfile),
      switchMap((action: LoadProfileAction) =>
        /**
         * Do not use AxiosObservable. In staging-web AxiosObservable is not recongnized as
         * observable by rxjs.
         * https://github.com/nrwl/nx/issues/2125#issuecomment-560680297
         */
        forkJoin([
          Axios.request({
            url: `user/${action.user.companyId}/${action.user.userId}/profile`,
          }),
          Axios.request({
            url: `user/details`,
          }),
        ]).pipe(
          mergeMap(([profileResponse, detailsResponse]) => {
            const profile = profileResponse.data
            const details = detailsResponse.data

            const customer: Customer = {
              ...details.data,
              general: profile.data,
            }

            if (!customer.general.isSubUser) {
              // it is important to set those permissions here as the
              // permission model includes negative and positive permissions
              // main users only get positive permissions not the negative ones
              // an example for a negative permission is commissionIsObligatory
              // if this is set the customer MUST set a commission for every cart
              // which is intended to be used for subusers only
              customer.general.permissions.subUser = getMainUserPermissions()
            }

            return defer(async () => {
              const usermeta = await db.getLocal<CustomerMetaData>('usermeta')
              await usermeta?.incrementalModify((data) => {
                data = {
                  ...data,
                  isFetching: false,
                  lastUpdated: new Date().getTime(),
                  userId: action.user.userId,
                  companyId: action.user.companyId || '',
                }
                return data
              })
              const user = await db.getLocal<Customer>('user')
              await user?.incrementalModify((data) => {
                data = { ...data, ...customer }
                return data
              })

              return customer
            })
          }),
          concatMap((customer: Customer) => of(loadUserProfileResult(customer))),
          catchError((error) => {
            error.message = 'error in ' + createLoadUserProfile.name + ' ' + error.message
            handleError(error)
            return of(loadUserProfileResult(undefined, error))
          })
        )
      )
    )
}

const createSaveCustomerSettingsEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$) =>
    actions$.pipe(
      ofType(CustomerActionTypes.SaveCustomerSettings),
      switchMap((action: SaveCustomerSettingsAction) =>
        Axios.request({
          method: 'POST',
          url: '/user/settings',
          data: action.settings,
          headers: {
            'Content-Type': 'application/json',
          },
        }).pipe(withLatestFrom(of(action)))
      ),
      concatMap(([response, action]: [AxiosResponse, SaveCustomerSettingsAction]) => {
        return defer(async () => {
          const user = await db.getLocal<Customer>('user')
          const updatedDoc = await user?.incrementalModify((data: Customer) => {
            data.general.defaultPjId = action.settings.defaultProjectId
            data.user.customerSettings.creditLimitWarning = action.settings.creditLimitWarning
            data.user.customerSettings.orderConfirmation = action.settings.orderConfirmation

            return data
          })

          return updatedDoc?.toJSON()
        })
      }),
      map((customer: Customer) => saveCustomerSettingsResult(customer)),
      catchError((error) => {
        error.message = 'error in ' + createSaveCustomerSettingsEpic.name + ' ' + error.message
        handleError(error)
        return of(saveCustomerSettingsResult(undefined, error))
      })
    )
}

const createChangeImageEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<ChangeImageAction>) =>
    actions$.pipe(
      ofType(CustomerActionTypes.ChangeImage),
      switchMap((action: ChangeImageAction) => {
        const formData: FormData = new FormData()
        formData.append('file', dataURItoBlob(action.base64Image, action.mime), 'filename')

        return Axios.post('user/uploadCustomerImage', formData).pipe(
          concatMap((response: AxiosResponse<ProfileImageUploadResponse>) =>
            defer(async () => {
              const user = await db.getLocal<Customer>('user')
              await user?.incrementalModify((user) => {
                user.user.picture = response.data.file[0].url
                return user
              })

              return response
            })
          ),
          map(
            (response: AxiosResponse<ProfileImageUploadResponse>) => changeImageResult(),
            catchError((err: AxiosError) => {
              err.message = 'error in ' + createChangeImageEpic.name + ' ' + err.message
              handleError(err)
              return of(changeImageResult(err))
            })
          )
        )
      })
    )
}

const createDeleteImageEpic = () => {
  return (actions$: Observable<DeleteImageAction>) =>
    actions$.pipe(
      ofType(CustomerActionTypes.DeleteImage),
      switchMap((action: DeleteImageAction) => {
        return Axios.get('user/deleteCustomerImage').pipe(
          map(
            () => deleteImageResult(),
            catchError((err: AxiosError) => {
              err.message = 'error in ' + createDeleteImageEpic.name + ' ' + err.message
              handleError(err)
              return of(deleteImageResult(err))
            })
          )
        )
      })
    )
}

const createUpdateCardEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<UpdateCardAction>) =>
    actions$.pipe(
      ofType(CustomerActionTypes.UpdateCard),
      switchMap((action: UpdateCardAction) =>
        Axios.post('user/card', { barcode: action.cardId }).pipe(
          concatMap((response: AxiosResponse<UserDetailsResponse>) =>
            defer(async () => {
              if (response.data.data) {
                const user = await db.getLocal<Customer>('user')
                await user?.incrementalModify((data) => {
                  data = { ...data, ...response.data.data }
                  return data
                })
                getEventSubscription().next({
                  type: EventType.Toast,
                  notificationType: NotificationType.Toast,
                  id: 'user card toast',
                  options: {
                    message: 'Die Kundenkarte wurde erfolgreich hinzugefügt',
                  },
                })
              }

              return updateCardResult()
            })
          ),
          catchError((error: AxiosError<UserDetailsResponse>) => {
            error.message = 'error in ' + createUpdateCardEpic.name + ' ' + error.message
            handleError(error)

            let msg = 'Die Kundenkarte konnte nicht gespeichert werden'
            if (error.response?.data?.messages && error.response.data.messages.length > 0) {
              msg = error.response.data.messages[0].message
            }
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.Toast,
              id: 'user card toast error',
              options: {
                message: msg,
              },
            })
            return of(updateCardResult(error))
          })
        )
      )
    )
}

const createSaveAdressEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$) =>
    actions$.pipe(
      ofType(CustomerActionTypes.SaveNewAddress),
      switchMap((action: SaveNewAddressAction) =>
        Axios.request({
          method: 'POST',
          url: 'user/saveAddress',
          data: action.address,
          headers: {
            'Content-Type': 'application/json',
          },
        }).pipe(withLatestFrom(of(action)))
      ),
      concatMap(
        ([response, action]: [
          AxiosResponse<ProfileResponseRefined>,
          SaveCustomerSettingsAction
        ]) => {
          return defer(async () => {
            const user = await db.getLocal<Customer>('user')

            const generalInfo: CustomerGeneralInformation = response.data.data
            if (!generalInfo.isSubUser) {
              generalInfo.permissions.subUser = getMainUserPermissions()
            }
            await user?.set('general', generalInfo)
          })
        }
      ),
      map(() => noop()),
      catchError((error) => {
        error.message = 'error in ' + createSaveAdressEpic.name + ' ' + error.message
        handleError(error)
        return of(loadUserProfileResult(undefined, error))
      })
    )
}

const createUpdateUserV2 = <Action>(
  requestUser: (action: Action) => Promise<UserV2>,
  db: RxDatabase<CollectionsOfDatabase>
) => {
  return (action: Action) => {
    return defer(async () => {
      const user = await requestUser(action)
      const userBase = await db.getLocal<UserV2>('userv2')
      await userBase?.incrementalModify((data) => ({ ...data, ...user }))
      return user
    }).pipe(
      retry(1),
      mergeMap((result: UserV2) => of(loadUserGraphQLResult(result))),
      catchError((error) => {
        error.message = 'error in ' + createUpdateUserV2.name + ' ' + error.message
        handleError(error)
        return of(loadUserGraphQLResult(undefined, error))
      })
    )
  }
}

export const createLoadUserGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const updateUserV2 = createUpdateUserV2<LoadUserGraphQLAction>(async () => {
    const response = await apolloClient.query<getUserV2Result>({
      query: gqlQueryUserV2,
    })

    return response.data.getUser
  }, db)
  return (actions$: Observable<LoadUserGraphQLAction>) =>
    actions$.pipe(
      ofType(CustomerActionTypes.LoadUserGraphql),
      concatMap((action: LoadUserGraphQLAction) => updateUserV2(action))
    )
}

export const createUpdateUserSettings = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const updateUserV2 = createUpdateUserV2<UpdateUserSettingsAction>(async (action) => {
    const response = await apolloClient.mutate<{ updateUserSettings: UserV2 }>({
      mutation: gqlUpdateUserV2,
      variables: {
        input: action.payload,
      },
    })
    const user = response.data?.updateUserSettings
    if (!user) {
      throw new Error("updateUserSettings: response doesn't have new user")
    }

    return user
  }, db)

  return (actions$: Observable<UpdateUserSettingsAction>) => {
    return actions$.pipe(
      ofType(CustomerActionTypes.UpdateUserSettings),
      switchMap((action) => updateUserV2(action))
    )
  }
}

const createDeleteUserTokenEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const deleteToken = async () => {
    const pushToken = await db.getLocal('pushToken')
    const token = pushToken?.get('token')

    if (!token) {
      return
    }

    try {
      const response = await apolloClient.mutate({
        mutation: deleteFirebasePushToken,
        variables: {
          token,
        },
      })
      return { deleteToken: response.data.deleteToken }
    } catch (err) {
      err.message = 'error in ' + deleteToken.name + ' ' + err.message
      handleError(err)
      throw new Error(err)
    }
  }

  return (actions$: Observable<DeleteUserToken>) => {
    return actions$.pipe(
      ofType(CustomerActionTypes.DeleteUserToken),
      switchMap(async () => {
        await deleteToken()

        return noop()
      })
    )
  }
}

export const initAllCustomerEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return [
    createLoadUserProfile(db),
    createSaveCustomerSettingsEpic(db),
    createSaveAdressEpic(db),
    createChangeImageEpic(db),
    createDeleteImageEpic(),
    createUpdateCardEpic(db),
    createLoadUserGraphQLEffect(db, apolloClient),
    createUpdateUserSettings(db, apolloClient),
    createDeleteUserTokenEpic(db, apolloClient),
  ]
}
