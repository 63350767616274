import React, { useState } from 'react'
import { useIntervalInActiveTab } from '../hooks/useIntervalInActiveTab'
import MaintenanceGif from 'assets/img/maintenance.gif'
import styles from './Maintenance.module.scss'

const MAINTENANCE_API_ROUTE = '/api/maintenance'
const VERSION_CHECK_INTERVAL_IN_MS = 60000 //one minute
export const Maintenance: React.FC = (props) => {
  const [showMaintenance, setShowMaintenance] = useState(false)
  const { children } = props
  const persistedMaintenanceStatus = JSON.parse(localStorage.getItem('showMaintenance') as string)

  const isInternalOrigin = (): boolean => {
    return (
      window?.location?.origin === 'http://localhost:4200' ||
      window?.location?.origin === 'https://staging-shop-support.obeta.io' ||
      window?.location?.origin === 'https://shop-support.obeta.io'
    )
  }

  const writeMaintenanceStatusToLocalStorage = (value: boolean) => {
    localStorage.setItem('showMaintenance', JSON.stringify({ value }))
  }

  useIntervalInActiveTab(async () => {
    if (!isInternalOrigin()) {
      const response = await fetch(MAINTENANCE_API_ROUTE, { cache: 'no-store' })
      if (response.status === 200) {
        writeMaintenanceStatusToLocalStorage(true)
        setShowMaintenance(true)
      } else if (response.status === 204) {
        writeMaintenanceStatusToLocalStorage(false)
        setShowMaintenance(false)
      }
    }
  }, VERSION_CHECK_INTERVAL_IN_MS)

  return !isInternalOrigin() && (persistedMaintenanceStatus?.value || showMaintenance) ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.textWrapper} style={{ top: 120 + (window.innerHeight - 500) / 2 }}>
        <h1 className={styles.header1}>Sorry</h1>
        <h2 className={styles.header2}>Wir sind gleich wieder erreichbar</h2>
        <span className={styles.spanElement}>Aufgrund von Wartungsarbeiten ist unsere</span>
        <br />
        <span className={styles.spanElement}>Website für kurze Zeit nicht erreichbar.</span>
      </div>
      <img
        src={MaintenanceGif}
        alt={'wartungsarbeit'}
        className={styles.maintenanceImage}
        style={{ top: (window.innerHeight - 500) / 2 }}
      />
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  )
}
