import { EventType, getEventSubscription, NotificationType } from './pubSub'
import { ResponseMessage } from '@obeta/schema'

export function showMessage(
  responseMessage: ResponseMessage,
  action?: string,
  userId?: string
): void {
  const id = `updated-user-${new Date().getTime()}`
  getEventSubscription().next({
    type: EventType.Data,
    notificationType: NotificationType.UpdateUser,
    id: id,
    options: {
      message: responseMessage.message,
      type: responseMessage.type,
      action: action ?? undefined,
      userId: userId ?? undefined,
    },
  })
}
