import {
  initAllArticleEpics,
  initAllCartEpics,
  initAllOfferEpics,
  initAllTemplateEpics,
  initAllCustomerEpics,
  initAllOrderEpics,
  initAllMessageEpics,
  initAllUserEpics,
} from '@obeta/data/lib/epics'
import { BehaviorSubject, Observable } from 'rxjs'
import { mergeMap } from 'rxjs'
import { combineEpics } from 'redux-observable'
import { getCollection$, syncDataWithDatabase } from './bootstrap'
import { CollectionsOfDatabase, RxDatabase } from 'rxdb'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { GetCollectionSync } from '@obeta/models/lib/models/Db/index'
import { initRaffleEpics } from '@obeta/data/lib/epics/raffle-effects'
import { initDigitalBillingEpics } from '@obeta/data/lib/epics/digital-billing-effects'
import { initOpenPositionsEpics } from '@obeta/data/lib/epics/open-positions-effects'

export const boostrapRootEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  getCollectionSync: GetCollectionSync
) => {
  const combinedEpics = [
    ...initAllCartEpics(db, syncDataWithDatabase, getCollection$, apolloClient),
    ...initAllTemplateEpics(db, syncDataWithDatabase, getCollection$, apolloClient),
    ...initAllOrderEpics(db, apolloClient),
    ...initAllArticleEpics(db, syncDataWithDatabase, apolloClient),
    ...initAllCustomerEpics(db, syncDataWithDatabase, apolloClient),
    ...initAllOfferEpics(apolloClient),
    ...initAllMessageEpics(db),
    ...initAllUserEpics(db, apolloClient, getCollectionSync),
    ...initRaffleEpics(apolloClient),
    ...initDigitalBillingEpics(apolloClient),
    ...initOpenPositionsEpics(db, apolloClient),
  ]

  const epic$ = new BehaviorSubject<unknown>(combineEpics(...combinedEpics))
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const rootEpic = (action$, state$): Observable<any> =>
    epic$.pipe(mergeMap((epic: any) => epic(action$, state$)))
  /* eslint-enable @typescript-eslint/no-explicit-any */
  return { rootEpic, epic$ }
}
