import { CartItemResultErrorCodes } from '@obeta/schema'

export const isUnpurchasableProductsError = (errorCode: CartItemResultErrorCodes): boolean => {
  if (
    errorCode === 'ERROR_CUSTOM_PRODUCT_CAN_NOT_BE_ADDED_WITHOUT_OFFER' ||
    errorCode === 'ERROR_IS_PROMOTION_PRODUCT' ||
    errorCode === 'PRODUCT_IS_CUSTOM_RESPONSE_ERROR_CODE'
  ) {
    return true
  }
  return false
}
