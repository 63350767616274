import React, { useState } from 'react'
import { OverlayMenuItem } from '../OverlayMenuItem'
import {
  nativeDrawerMenuItems,
  mobileDrawerMenuItems,
  NativeDrawerMenuOverlays,
  PROJECTS,
} from '../../constants'
import { IMenuItem } from '../../types'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { Modal } from '../../../modal/Modal'
import { SupportOverlayContent } from './SupportOverlayContent/SupportOverlayContent'
import styles from './MenuItemsMobile.module.scss'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

interface IMenuItemsMobileProps {
  closeOverlayCb: (item: IMenuItem) => void
}

export const MenuItemsMobile: React.FC<IMenuItemsMobileProps> = (props) => {
  const { closeOverlayCb } = props
  const { isUserWithSessionBasedCart } = useUserDataV2()
  const [overlayType, setOverlayType] = useState<string | null>(null)
  const isNewSupplierOverview = useFeatureToggle('UseNewSupplierOverview')
  const useNewFastProductEntryPage = useFeatureToggle('UseNewFastArticleEntryPage')

  let options = isUiTarget('app')
    ? nativeDrawerMenuItems(isNewSupplierOverview)
    : mobileDrawerMenuItems(isNewSupplierOverview, useNewFastProductEntryPage)

  if (isUserWithSessionBasedCart) {
    options = options.filter((item) => item.title !== PROJECTS)
  }

  const openTab = (item: IMenuItem) => {
    if (item?.type === 'overlay') return setOverlayType(item.title)

    closeOverlayCb(item)
  }

  const onCloseOverlay = () => setOverlayType(null)
  return (
    <div className={styles['main-content-wrapper']}>
      {options.map((item, index) => (
        <OverlayMenuItem item={item} onOpenPage={openTab} key={index} />
      ))}

      {/*  For links that are not pages, but overlays */}
      <Modal
        isFullScreen={true}
        disableAutoFocus
        disableBodyScroll
        open={!!overlayType}
        onClose={onCloseOverlay}
      >
        <div className={styles.overlayWrapper}>
          {overlayType === NativeDrawerMenuOverlays.FEEDBACK_SUPPORT && (
            <SupportOverlayContent onClose={onCloseOverlay} />
          )}
        </div>
      </Modal>
    </div>
  )
}
