import { CartItemResultErrorCodes } from '@obeta/schema'

export const isDiverseProductsError = (errorCode: CartItemResultErrorCodes): boolean => {
  if (
    errorCode === 'ERROR_UNKNOWN_PRODUCT_SAP_ID' ||
    errorCode === 'ERROR_CANCELLED_PRODUCT' ||
    errorCode === 'ERROR_CART_ITEM_NOT_FOR_SALE'
  ) {
    return true
  }

  return false
}
