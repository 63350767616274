import { gql, useApolloClient } from '@apollo/client'
import {
  DeleteUserMutation,
  DeleteUserMutationVariables,
  TriggerEmailTokenRefreshMutation,
  TriggerEmailTokenRefreshMutationVariables,
  TriggerPasswordResetMailMutation,
  TriggerPasswordResetMailMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserPermissions,
} from '@obeta/schema'
import { useCallback, useState } from 'react'
import { showMessage } from '@obeta/utils/lib//userUpdate'

export interface updateUserPayload {
  subUserId?: string
  isActive?: boolean
  permissions?: UserPermissions
  email?: string
  name?: string
}

export interface triggerEmailTokenRefreshPayload {
  subUserId: string
  userId: string
}

export const gqlUpdateUser = gql`
  mutation updateUser($input: UserInput!) {
    updateUser(input: $input) {
      type
      message
    }
  }
`

export const gqlUserDelete = gql`
  mutation deleteUser($input: UserInput!) {
    deleteUser(input: $input) {
      type
      message
    }
  }
`

export const gqlTriggerEmailTokenRefresh = gql`
  mutation triggerEmailTokenRefresh($input: RefreshTokenInput!) {
    triggerEmailTokenRefresh(input: $input) {
      type
      message
    }
  }
`

export const gqlTriggerPasswordResetMail = gql`
  mutation triggerPasswordResetMail($input: String!) {
    triggerPasswordResetMail(input: $input) {
      type
      message
    }
  }
`

export const useUserActiveState = (isActive: boolean) => {
  const [userActive, setUserActive] = useState<boolean>(isActive)
  const client = useApolloClient()

  const dispatchUserUpdate = useCallback(
    async (payload: updateUserPayload) => {
      const response = await client.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
        mutation: gqlUpdateUser,
        variables: {
          input: payload,
        },
      })

      if (response.data?.updateUser[0]) {
        if (response.data?.updateUser[0].type === 'Success') {
          setUserActive(response.data?.updateUser[0].message.indexOf('deaktiviert') === -1)
        }
        showMessage(response.data?.updateUser[0])
      }
    },
    [client]
  )

  return { dispatchUserUpdate, userActive, setUserActive }
}

export const useUserPermissionUpdate = () => {
  const client = useApolloClient()

  return useCallback(
    async (payload: updateUserPayload) => {
      const permissions = { ...payload.permissions, __typename: undefined }
      const response = await client.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
        mutation: gqlUpdateUser,
        variables: {
          input: { ...payload, permissions: permissions },
        },
      })

      if (response.data?.updateUser[0]) {
        showMessage(response.data?.updateUser[0])
      }
    },
    [client]
  )
}

export const useUserUpdate = () => {
  const client = useApolloClient()

  return useCallback(
    async (payload: updateUserPayload) => {
      const response = await client.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
        mutation: gqlUpdateUser,
        variables: {
          input: payload,
        },
      })

      if (response.data?.updateUser && response.data?.updateUser.length > 0) {
        let allMessages = ''
        let allType = 'Success'
        response.data?.updateUser.forEach((responseMessage, index) => {
          allMessages += ' ' + responseMessage.message
          if (responseMessage.type !== 'Success') {
            allType = 'Error'
          }
        })
        showMessage({ message: allMessages, type: allType })
      }
    },
    [client]
  )
}

export const useUserDelete = () => {
  const client = useApolloClient()

  return useCallback(
    async (payload: updateUserPayload) => {
      const response = await client.mutate<DeleteUserMutation, DeleteUserMutationVariables>({
        mutation: gqlUserDelete,
        variables: {
          input: payload,
        },
      })

      if (response.data?.deleteUser) {
        showMessage(response.data?.deleteUser)
      }
    },
    [client]
  )
}

export const useTriggerEmailTokenRefresh = () => {
  const client = useApolloClient()

  return useCallback(
    async (payload: triggerEmailTokenRefreshPayload) => {
      const response = await client.mutate<
        TriggerEmailTokenRefreshMutation,
        TriggerEmailTokenRefreshMutationVariables
      >({
        mutation: gqlTriggerEmailTokenRefresh,
        variables: {
          input: payload,
        },
      })

      if (response.data?.triggerEmailTokenRefresh) {
        showMessage(response.data?.triggerEmailTokenRefresh)
      }
    },
    [client]
  )
}

export const useTriggerPasswordReset = () => {
  const client = useApolloClient()

  return useCallback(
    async (payload: string) => {
      const response = await client.mutate<
        TriggerPasswordResetMailMutation,
        TriggerPasswordResetMailMutationVariables
      >({
        mutation: gqlTriggerPasswordResetMail,
        variables: {
          input: payload,
        },
      })

      if (response.data?.triggerPasswordResetMail) {
        showMessage(response.data?.triggerPasswordResetMail)
      }
    },
    [client]
  )
}
